// theMovieDB APIs

import { url } from "./config";

const getPopular = (page, genreApiString) => {
  const encodedGetPopular = btoa(
    `https://api.themoviedb.org/3/discover/movie?include_adult=false&include_video=false&language=en-US&page=${page}&sort_by=popularity.desc&with_genres=${genreApiString}`
    // `https://api.themoviedb.org/3/movie/popular?language=en-US&page=${page}&with_genres=${genreApiString}`
  );
  console.log(encodedGetPopular);

  return `${url}/proxy?url=${encodedGetPopular}`;
};

const getSearch = (search, page) => {
  const encodedGetSearch = btoa(
    `https://api.themoviedb.org/3/search/movie?query=${search}&include_adult=false&language=en-US&page=${page}`
  );
  return `${url}/proxy?url=${encodedGetSearch}`;
};

const getMoviebyID = (id) => {
  const encodedGetMoviebyID = btoa(
    `https://api.themoviedb.org/3/movie/${id}?language=en-US`
  );
  return `${url}/proxy?url=${encodedGetMoviebyID}`;
};

const getReleaseDate = (id) => {
  const encodedGetReleaseDate = btoa(
    `https://api.themoviedb.org/3/movie/${id}/release_dates`
  );
  return `${url}/proxy?url=${encodedGetReleaseDate}`;
};

const getTrailers = (id) => {
  const encodedGetTrailers = btoa(
    `https://api.themoviedb.org/3/movie/${id}/videos?language=en-US`
  );
  return `${url}/proxy?url=${encodedGetTrailers}`;
};

const getRecommendations = (id) => {
  const encodedGetRecommendations = btoa(
    `https://api.themoviedb.org/3/movie/${id}/recommendations`
  );
  return `${url}/proxy?url=${encodedGetRecommendations}`;
};

const encodedGenreList = btoa(
  `https://api.themoviedb.org/3/genre/movie/list?language=en`
);
const genreList = `${url}/proxy?url=${encodedGenreList}`;

// dataBase APIs

const registerUser = (email, password) => {
  const encodedRegister = btoa(
    `https://api.themovielounge.co.uk/account/register`
  );
  return `${url}/proxy/databasePostRegister/${email}/${password}?url=${encodedRegister}`;
};

const loginUser = (email, password) => {
  const encodedLogin = btoa(`https://api.themovielounge.co.uk/account/login`);
  return `${url}/proxy/databasePostLogin/${email}/${password}?url=${encodedLogin}`;
};

const getAllAvgRatings = () => {
  const encodedgetAllAvgRatings = btoa(
    `https://api.themovielounge.co.uk/account/returnAllAvgRating`
  );
  return `${url}/proxy/database?url=${encodedgetAllAvgRatings}`;
};

const userFavourites = (token) => {
  const encodedUserFavourites = btoa(
    `https://api.themovielounge.co.uk/useractions/returnFavourites`
  );
  return `${url}/proxy/database/${token}?url=${encodedUserFavourites}`;
};

const checkUserData = (token, movieid) => {
  const encodedCheckFavourites = btoa(
    `https://api.themovielounge.co.uk/useractions/actions/${movieid}`
  );
  return `${url}/proxy/database/${token}?url=${encodedCheckFavourites}`;
};

const setFavourites = (token, movieid, fave) => {
  const encodedSetFavourites = btoa(
    `https://api.themovielounge.co.uk/useractions/add`
  );
  return `${url}/proxy/databasePostFave/${token}/${movieid}/${fave}?url=${encodedSetFavourites}`;
};

const updateFavourites = (token, movieid, fave) => {
  const encodedUpdateFavourites = btoa(
    `https://api.themovielounge.co.uk/useractions/update/${movieid}`
  );
  return `${url}/proxy/databasePatchFave/${token}/${movieid}/${fave}?url=${encodedUpdateFavourites}`;
};

const setRatings = (token, movieid, currentRating) => {
  const encodedSetRatings = btoa(
    `https://api.themovielounge.co.uk/useractions/add`
  );
  return `${url}/proxy/databasePostRating/${token}/${movieid}/${currentRating}?url=${encodedSetRatings}`;
};

const updateRatings = (token, movieid, currentRating) => {
  const encodedUpdateRatings = btoa(
    `https://api.themovielounge.co.uk/useractions/update/${movieid}`
  );
  return `${url}/proxy/databasePatchRating/${token}/${movieid}/${currentRating}?url=${encodedUpdateRatings}`;
};

const faveStatus = (token, id) => {
  const encodedFaveStatus = btoa(
    `https://api.themovielounge.co.uk/useractions/checkFavourite/${id}`
  );
  return `${url}/proxy/database/${token}?url=${encodedFaveStatus}`;
};

const ratingStatus = (token, id) => {
  const encodedRatingStatus = btoa(
    `https://api.themovielounge.co.uk/useractions/checkRating/${id}`
  );
  return `${url}/proxy/database/${token}?url=${encodedRatingStatus}`;
};

const deleteUser = (token, password) => {
  const encodedDeleteUser = btoa(
    `https://api.themovielounge.co.uk/useractions/delete`
  );
  return `${url}/proxy/databaseDelete/${token}/${password}?url=${encodedDeleteUser}`;
};

const updatePassword = (token, password, password2) => {
  const encodedChangePassword = btoa(
    `https://api.themovielounge.co.uk/useractions/changePassword`
  );
  return `${url}/proxy/database/${token}/${password}/${password2}?url=${encodedChangePassword}`;
};

export {
  genreList,
  getPopular,
  getSearch,
  getMoviebyID,
  getReleaseDate,
  getTrailers,
  getRecommendations,
  getAllAvgRatings,
  userFavourites,
  setFavourites,
  updateFavourites,
  checkUserData,
  setRatings,
  updateRatings,
  registerUser,
  loginUser,
  faveStatus,
  ratingStatus,
  deleteUser,
  updatePassword,
};
